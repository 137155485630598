
.box{
    margin-top: 3rem;
    border-radius: 20px;
    box-shadow: -6px 4px 10px rgba(0, 0, 0, 0.14);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f0f0eb66;
    height: 55vh;
    color: white;
    transition: all 250ms;
}

.box:hover,
.box:focus {
    cursor: pointer;
    background-color: hsl(206deg 100% 73.3% / 59%);
    transform: scale(.75);
}

.box h4{
    font-size: 3rem;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-weight: 700;    
}
@media (max-width: 1100px) {

    .box h4{
        font-size: 2rem; 
    }
}

@media (max-width: 900px) {
    .box {
      height: 30vh;
      margin-top: 1rem;
    }

}
