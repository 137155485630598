.vms-filters-wrapper{
    margin-bottom: 1rem !important;
}
.vms-filters-wrapper select,
.vms-filters-wrapper input{
    border: 1px solid #1e4e7e8c !important;
}
.vms-filters-wrapper .input-group-text .fa{
    padding: 1px 0px;
}
.vms-filters-wrapper .input-group-text{
    border: 1px solid #1e4e7e8c !important;
    background-color: #1e4e7e;
    color: white;
    border-radius: 0px;
}
.vms-table-wrapper {
    padding: 0px 4rem;
    max-height: 70vh;
    overflow: auto;
}
.vms-table-wrapper{
    max-height: 60vh;
    overflow: auto;
}
.vms-table-wrapper::-webkit-scrollbar {
    width: 7px;
}
.vms-table-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #393E5C;
}
.vms-table-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(228, 232, 244);
}
.vms-admin-container .btn {
    font-size: 100%;
}
.vms-admin-container .form-control {
    padding: 0.275rem 0.75rem !important;
    font-size: 0.85em !important;
    border: none !important;
    border-radius: 0px !important;
}

.vms-table-queue th{
    white-space: nowrap;
}

.vms-table-queue td.limit-text{
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1440px) {
  .vms-table-wrapper {
    font-size: 80%;
  }
}
@media (max-width: 1024px) {
  .vms-table-wrapper {
    font-size: 70%;
    padding: 0 2rem;
  }
}
@media (max-width: 768px) {
  .vms-table-wrapper {
    font-size: 50%;
  }
}
@media (min-width: 1199px) {
    .vms-admin-container {
      margin-left: -7rem;
    }
  }