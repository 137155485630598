@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
.vms-sidebar{
    min-height: 100%;
    background-color: #393E5C;
    border-right: 1px solid rgb(212 224 232/63%);
    box-shadow: 10px 5px 5px rgb(50 50 93 / 10%);
}
.vms-sidebar ul li.active{
    background-color: #9fa2b435;
    border-left: 2px solid white;
}
.vms-sidebar ul li.active a{
    color: white !important;
    font-weight: 600;
}
.vms-sidebar ul li{
    list-style: none;
    text-align: left;
    padding: 0 10px;
    margin: 5px 0px;
}
.vms-sidebar ul li a:focus,
.vms-sidebar ul li:hover a{
    font-weight: 600;
    color: #fff;
}
.vms-sidebar ul li a{
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
}

@media (min-width: 1199px){
    .vms-sidebar{
        margin-right: 7rem;
    }
}

@media (max-width: 1440px) {
    .vms-sidebar .nav-link {
        text-align: center;
    }
    .vms-sidebar .nav-link label {
        display: none;
    }
}
.vms-dashboard-wrapper .card {
    padding: .25rem;
    margin: 1rem 2rem;
    text-align: center;
    border-radius: 0px;
    border: none;
    /* background-color: transparent; */
}

.vms-dashboard-filter-wrapper{
    margin-top: 1rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.vms-dashboard-filter-wrapper .col{
    padding: 0rem 1rem !important;
}
.vms-dashboard-filter-wrapper .form-control{
    width: 85%;
    right: 0;
}
.vms-dashboard-filter-wrapper label{
    font-weight: 500;
    padding-bottom: 0.25rem;
    text-transform: uppercase;
}
.vms-dashboard-wrapper .card .vms-dashboard-table-title,
.vms-dashboard-wrapper .card h2 {
    color: #393E5C;
}
.vms-dashboard-wrapper .card h6 {
    color: #DB9360;
    font-weight: 700;
    padding-top: 5px;
}
.vms-dashboard-wrapper .card .vms-dashboard-table-title{
    padding-top: 1rem;
    font-size: 1.75rem ;
    font-weight: 700;
}
.vms-dashboard-wrapper .card .vms-dashboard-table-wrapper{
    height: 45vh;
    text-align: left;
    overflow-y: auto;
}
.vms-dashboard-table-wrapper::-webkit-scrollbar {
    width: 7px;
}
.vms-dashboard-table-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #393E5C;
}
.vms-dashboard-table-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
}
@media (max-width: 1440px) {
    .vms-dashboard-wrapper .card .vms-dashboard-table-title{
        font-size: 1.5em;
    }
    .vms-dashboard-wrapper .card h6{
        font-size: 1em;
    }
    .vms-dashboard-wrapper .card h2{
        font-size: 2em;
    }
    .vms-dashboard-wrapper .card .vms-dashboard-table-wrapper{
        height: 33vh;
    }
}
@media (max-width: 1024px) {
    .vms-dashboard-wrapper .card .vms-dashboard-table-title{
        font-size: 1.25em;
    }
    .vms-dashboard-wrapper .card h6{
        font-size: 0.75em;
    }
    .vms-dashboard-wrapper .card h2{
        font-size: 1.5em;
    }
    .vms-dashboard-wrapper .card .vms-dashboard-table-wrapper{
        height: 22vh;
        font-size: 80%;
    }
    .vms-dashboard-wrapper .card {
        padding: 0rem;
        margin: 0.5rem 1.5rem;
    }
}
.vms-filters-wrapper{
    margin-bottom: 1rem !important;
}
.vms-filters-wrapper select,
.vms-filters-wrapper input{
    border: 1px solid #1e4e7e8c !important;
}
.vms-filters-wrapper .input-group-text .fa{
    padding: 1px 0px;
}
.vms-filters-wrapper .input-group-text{
    border: 1px solid #1e4e7e8c !important;
    background-color: #1e4e7e;
    color: white;
    border-radius: 0px;
}
.vms-table-wrapper {
    padding: 0px 4rem;
    max-height: 70vh;
    overflow: auto;
}
.vms-table-wrapper{
    max-height: 60vh;
    overflow: auto;
}
.vms-table-wrapper::-webkit-scrollbar {
    width: 7px;
}
.vms-table-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #393E5C;
}
.vms-table-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(228, 232, 244);
}
.vms-admin-container .btn {
    font-size: 100%;
}
.vms-admin-container .form-control {
    padding: 0.275rem 0.75rem !important;
    font-size: 0.85em !important;
    border: none !important;
    border-radius: 0px !important;
}

.vms-table-queue th{
    white-space: nowrap;
}

.vms-table-queue td.limit-text{
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1440px) {
  .vms-table-wrapper {
    font-size: 80%;
  }
}
@media (max-width: 1024px) {
  .vms-table-wrapper {
    font-size: 70%;
    padding: 0 2rem;
  }
}
@media (max-width: 768px) {
  .vms-table-wrapper {
    font-size: 50%;
  }
}
@media (min-width: 1199px) {
    .vms-admin-container {
      margin-left: -7rem;
    }
  }

.logo {
  padding: 0;
  margin-right: auto;
  align-content: center;
}

.menu-icon {
  display: none;
  margin-top: auto;
  margin-bottom: auto;
}

.nav-elements {
  z-index: 1;
  margin-left: auto;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}



.nav-elements ul a.active {
  font-weight: 700;
  color: #17043f;
}

@media (max-width: 992px) {
  
  .vms-header .navItem {
    display: none;
  }

  .container {
    flex-wrap: nowrap;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 45px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 45px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin: 15px 22px;
  }

  .nav-elements ul a {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .nav-elements.active {
    width: 100%;
  }

  .nav-elements ul li {
    text-align: center;
  }
}

.vms-header{
  padding: 0px 17px;
  height: 5vh; 
  background-color: #f0f0eb;
  align-items: center; 
}

.vms-header .navbar-nav {
  width: 100%;
  display: block;
}
.vms-header .navbar-nav .nav-item {
  display: inline-block;
}
.vms-header .navbar-nav .nav-link {
  color: black !important;
  font-weight: 500;
}
.vms-header .navbar-nav .dropdown-menu {
  right: 0;
}

.vms-header img:hover{
  cursor: pointer;
}

.vms-header a {
  padding: 0.65rem 0.75rem;
  font-size: 15px;
  display: inline-block;
  letter-spacing: 0.5px;
  text-decoration:none;
  color: #000;
}

.vms-header a:hover {
  color: #17043f;
}

.vms-header a.active {
font-weight: 700;
color: #17043f;
}
.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 500ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
}

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
}
.right-arrow i,
.left-arrow i{
    padding: 0px 10px;
    color: #db9360;
    font-size: 1.75rem;
    font-weight: 700 !important;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}
.carousel-content.show-1 > * {
    width: 100%;
}

.carousel-content.show-2 > * {
    width: 50%;
}

.carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.carousel-content.show-4 > * {
    width: calc(100% / 4);
}
.vms-queue-sidebar .vms-queue-sidebar-title{
    background-color: rgb(57 62 92);
    color: white;
    letter-spacing: 2px;
    text-align: center;
    padding: 7px;
    font-weight: 800;
}
.vms-queue-sidebar .vms-queue-item h3{
    font-size: 24px;
    text-transform: capitalize;
}
.vms-queue-sidebar .vms-queue-item .vms-avatar{
    border-color: green;
    border-width: 3px;
}
.vms-queue-sidebar .vms-queue-item{
    padding: 12px 5px 0px 5px !important;
    border-bottom: 1px solid #E0E0E0;
    padding: 1rem 0.75rem; 
    cursor: pointer;
    width: auto;
}
.vms-queue-sidebar .vms-queue-item p {
    font-size: 1rem;
    line-height: 15px;
    text-transform: capitalize;
}
.vms-queue-sidebar .vms-queue-item p.vms-queue-item-reasons i,
.vms-queue-sidebar .vms-queue-item p.vms-queue-item-reasons,
.vms-queue-sidebar .vms-queue-item p.vms-queue-item-moves{
    font-size: 0.85rem;
    font-weight: 400 !important;
}

.vms-queue-sidebar .vms-queue-item:hover p{
    color: white;
}
.vms-queue-sidebar .vms-queue-item:hover{
    background-color: #db9360 !important;
    transition: 0.5s; 
    -webkit-transform: scale(1.02); 
            transform: scale(1.02);
}

.vms-main-content-container .vms-queue-content-agent-wrapper{
    background-color: rgb(57 62 92);
    color: white;
}
.vms-driver-taglist{
    background-color: #393e5c; 
    padding:4px 10px 4px 10px !important;
    color: #fff;
    margin: 25px 0px;
}
.vms-details-list-wrapper{
    max-height: 45em;
    overflow-y: auto;
    overflow-x: hidden;
}

.vms-details-list-wrapper::-webkit-scrollbar {
    width: 10px;
  }
.vms-details-list-wrapper::-webkit-scrollbar-thumb {
    border-radius: 13px;
    background-color: #393E5C;
}
.vms-details-list-wrapper::-webkit-scrollbar-track {
    background-color: rgb(228, 232, 244);
}
.vms-main-content-container .vms-queue-content-agent-wrapper .vms-avatar{
    width: 75px;
    border-width: 5px;
}
.vms-main-content-container .vms-queue-content-agent-wrapper .vms-agent-detail-card{
    vertical-align: middle;
    display: inline-block;
    text-transform: uppercase;
}
.vms-main-content-container .vms-queue-content-agent-wrapper .vms-agent-detail-card h3{
    padding-top: 10px;
    letter-spacing: 2px;
}
.vms-main-content-container .vms-queue-content-agent-wrapper .vms-agent-detail-card p{
    font-size: 1rem;
}
.vms-queue-content-wrapper{
    padding: 2rem;
    width: 100%;
}
.vms-queue-content-driver-wrapper{
    margin: 1.5rem 0rem !important;
    text-align: center;
    text-transform: capitalize;
}

.vms-queue-content-driver-wrapper .vms-avatar{
    width: 5rem;
}
.vms-queue-content-driver-wrapper .vms-queue-content-driver-details{
    border-bottom: 2px solid rgb(46, 46, 46);
}
.vms-queue-content-driver-wrapper h3{
    font-size: 36px;
    padding-top: 1rem;
}
.vms-queue-content-driver-wrapper ul{
    color: #A6A6A6;
    list-style: decimal-leading-zero;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    text-align: left;
    max-width: 100px;
}
.vms-queue-content-driver-wrapper p{
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 20px;
}
.vms-ticket-modal .modal-body p.vms-driver-reasons,
.vms-queue-content-driver-wrapper p.vms-driver-reasons{
    font-weight: 700;
}
.vms-ticket-modal .modal-body p.vms-driver-notes,
.vms-queue-content-driver-wrapper p.vms-driver-notes{
    text-transform: none !important;
}
.vms-ticket-modal .modal-body p.vms-driver-notes{
    padding-top: 7px;
    font-weight: 500;
}

.vms-ticket-modal{
    max-width: 50% !important;
}
.vms-ticket-modal .modal-body{
    padding: 2rem;
}
.vms-ticket-modal .modal-body h2{
    text-transform: capitalize;
}
.vms-ticket-modal .modal-body p{
    font-size: 1rem;
    text-transform: capitalize !important;
}
.vms-ticket-modal .modal-body .vms-avatar{
    border-width: 3px !important;
    border-color: green;
}
.vms-ticket-modal .modal-body .vms-driver-data{
    align-self: center;
}
.vms-ticket-modal .modal-body .vms-next-driver-wrapper{
    background-color: #db936078;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 3px solid rgb(136, 134, 134);
    border-bottom: 3px solid rgb(136, 134, 134);
}
.vms-ticket-modal .form-group{
    margin-bottom: 2em;
}
.vms-ticket-modal label{
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.vms-queue-sidebar {
    z-index: 1050;
    position: static;
    background-color: white;
    box-shadow: none;
    height: 100%;
}

.vms-queue-sidebar.show-sidebar {
    position: absolute;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
}

.toggle-sidebar-icon {
    width: 15px;
    position: absolute;
    top: 50%;
    z-index: 99;
}
.vms-checkin-container {
  font-family: "Poppins";
  overflow: auto;
  background-image: url(/static/media/checkin-bg.9aa5bda2.png);
  height: 100vh;
  background-size: cover;
  background-color: #000000;
  background-position: center !important;
  background-attachment: fixed;
  background-repeat: no-repeat !important;
}
.vms-checkin-container h2 {
  font-size: 50px;
  color: white;
  line-height: 75px;
  font-weight: 400;
  margin-top: 3rem;
  text-transform: uppercase;
}
.vms-checkin-wrapper {
  padding: 60px 50px !important;
}
@media (max-width: 1440px) {
  .vms-checkin-wrapper {
    padding: 20px 20px !important;
  }
}
.vms-checkin-container .card {
  padding: 45px;
  margin-top: 80px;
  border-radius: 19px;
  box-shadow: -6px 4px 10px rgba(0, 0, 0, 0.14);
}
.vms-checkin-container .card-body span {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
}
.vms-checkin-container .btn {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  padding: 19px 54px;
}
.vms-checkin-container .card-body label {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 21px;
  letter-spacing: 0.15px;
}
.vms-checkin-container .card-body .input-group .form-control {
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  border: 1px solid #979797;
}
.vms-checkin-container .card-body .input-group .input-group-prepend {
  background-color: white !important;
}
.vms-checkin-container .card-body .input-group .input-group-text {
  border: 1px solid #979797;
  background-color: white;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 18px;
  padding: 15px;
}
.vms-checkin-wrapper a{
  text-decoration: none;
}
.vms-checkin-driver-info .form-group label {
  font-weight: bold !important;
  padding-bottom: 10px !important;
}
.vms-checkin-driver-info .form-group {
  padding-bottom: 15px;
}
.vms-checkin-driver-info .muted-text {
  color: #a6a6a6;
  font-size: 16px;
  line-height: 12px;
}
.vms-checkin-thank-you {
  padding: 7rem 0 !important;
}
.vms-checkin-thank-you h3 {
  font-size: 42px;
  line-height: 55px;
}
.vms-checkin-go-back-link {
  padding: 0.5rem 1rem;
  font-weight: 400;
  font-size: 90%;
  color: #8f8f8f;
  cursor: pointer;
}

.vms-avatar {
  display: inline-block;
  border-radius: 50%;
  border: 3px solid #ffa140;
}


.box{
    margin-top: 3rem;
    border-radius: 20px;
    box-shadow: -6px 4px 10px rgba(0, 0, 0, 0.14);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f0f0eb66;
    height: 55vh;
    color: white;
    transition: all 250ms;
}

.box:hover,
.box:focus {
    cursor: pointer;
    background-color: hsl(206deg 100% 73.3% / 59%);
    -webkit-transform: scale(.75);
            transform: scale(.75);
}

.box h4{
    font-size: 3rem;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-weight: 700;    
}
@media (max-width: 1100px) {

    .box h4{
        font-size: 2rem; 
    }
}

@media (max-width: 900px) {
    .box {
      height: 30vh;
      margin-top: 1rem;
    }

}

body {
  margin: 0;
  font-family: "Poppins", "Roboto", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-size: 0.75em;
}

body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 13px;
  background-color: #393E5C;
}
body::-webkit-scrollbar-track {
  background-color: rgb(228, 232, 244);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters .col,
.no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.bg-muted {
  background-color: #f0f0eb;
}
.bg-blue {
  background-color: #393e5c;
}
.color-white {
  color: white !important;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
}
.border-green {
  border-color: #009806 !important;
}
.btn {
  border-radius: 10px;
  border: none;
}
.btn-secondary {
  background-color: #db9360;
}
.btn-primary {
  background-color: #393e5c;
}

.btn:hover,
.btn:focus {
  background-color: #5c636a;
}


.vms-main-wrapper {
  min-height: 95vh;
}
.vms-queue-sidebar {
  background-color: white;
}
.vms-main-content-container {
  background-color: #e4e8f4;
}
.limit-text {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
