
.logo {
  padding: 0;
  margin-right: auto;
  align-content: center;
}

.menu-icon {
  display: none;
  margin-top: auto;
  margin-bottom: auto;
}

.nav-elements {
  z-index: 1;
  margin-left: auto;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}



.nav-elements ul a.active {
  font-weight: 700;
  color: #17043f;
}

@media (max-width: 992px) {
  
  .vms-header .navItem {
    display: none;
  }

  .container {
    flex-wrap: nowrap;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 45px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 45px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin: 15px 22px;
  }

  .nav-elements ul a {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .nav-elements.active {
    width: 100%;
  }

  .nav-elements ul li {
    text-align: center;
  }
}

.vms-header{
  padding: 0px 17px;
  height: 5vh; 
  background-color: #f0f0eb;
  align-items: center; 
}

.vms-header .navbar-nav {
  width: 100%;
  display: block;
}
.vms-header .navbar-nav .nav-item {
  display: inline-block;
}
.vms-header .navbar-nav .nav-link {
  color: black !important;
  font-weight: 500;
}
.vms-header .navbar-nav .dropdown-menu {
  right: 0;
}

.vms-header img:hover{
  cursor: pointer;
}

.vms-header a {
  padding: 0.65rem 0.75rem;
  font-size: 15px;
  display: inline-block;
  letter-spacing: 0.5px;
  text-decoration:none;
  color: #000;
}

.vms-header a:hover {
  color: #17043f;
}

.vms-header a.active {
font-weight: 700;
color: #17043f;
}