.vms-dashboard-wrapper .card {
    padding: .25rem;
    margin: 1rem 2rem;
    text-align: center;
    border-radius: 0px;
    border: none;
    /* background-color: transparent; */
}

.vms-dashboard-filter-wrapper{
    margin-top: 1rem !important;
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}
.vms-dashboard-filter-wrapper .col{
    padding: 0rem 1rem !important;
}
.vms-dashboard-filter-wrapper .form-control{
    width: 85%;
    right: 0;
}
.vms-dashboard-filter-wrapper label{
    font-weight: 500;
    padding-bottom: 0.25rem;
    text-transform: uppercase;
}
.vms-dashboard-wrapper .card .vms-dashboard-table-title,
.vms-dashboard-wrapper .card h2 {
    color: #393E5C;
}
.vms-dashboard-wrapper .card h6 {
    color: #DB9360;
    font-weight: 700;
    padding-top: 5px;
}
.vms-dashboard-wrapper .card .vms-dashboard-table-title{
    padding-top: 1rem;
    font-size: 1.75rem ;
    font-weight: 700;
}
.vms-dashboard-wrapper .card .vms-dashboard-table-wrapper{
    height: 45vh;
    text-align: left;
    overflow-y: auto;
}
.vms-dashboard-table-wrapper::-webkit-scrollbar {
    width: 7px;
}
.vms-dashboard-table-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #393E5C;
}
.vms-dashboard-table-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
}
@media (max-width: 1440px) {
    .vms-dashboard-wrapper .card .vms-dashboard-table-title{
        font-size: 1.5em;
    }
    .vms-dashboard-wrapper .card h6{
        font-size: 1em;
    }
    .vms-dashboard-wrapper .card h2{
        font-size: 2em;
    }
    .vms-dashboard-wrapper .card .vms-dashboard-table-wrapper{
        height: 33vh;
    }
}
@media (max-width: 1024px) {
    .vms-dashboard-wrapper .card .vms-dashboard-table-title{
        font-size: 1.25em;
    }
    .vms-dashboard-wrapper .card h6{
        font-size: 0.75em;
    }
    .vms-dashboard-wrapper .card h2{
        font-size: 1.5em;
    }
    .vms-dashboard-wrapper .card .vms-dashboard-table-wrapper{
        height: 22vh;
        font-size: 80%;
    }
    .vms-dashboard-wrapper .card {
        padding: 0rem;
        margin: 0.5rem 1.5rem;
    }
}